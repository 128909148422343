export var code = {
  YesNO: [{
    key: 1,
    value: '是'
  }, {
    key: 0,
    value: '否'
  }],
  Language: [{
    key: 1,
    value: '简体中文'
  }, {
    key: 0,
    value: '英文'
  }, {
    key: 2,
    value: '繁体中文'
  }],
  PropType: ['date', 'input', 'multiSelect', 'number', 'password', 'percentage', 'singleSelect', 'textarea', 'time', 'timestamp', 'treeMultiSelect', 'treeSingleSelect']
};