var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "10px" } },
    [
      _c(
        "el-button",
        { attrs: { type: "warning" }, on: { click: _vm.refreshDD } },
        [_vm._v("刷新数据字典系统缓存")]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "el-form",
        { ref: "form" },
        [
          _c(
            "el-form-item",
            { attrs: { label: "请选择" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", filterable: true },
                  on: {
                    change: function ($event) {
                      return _vm.onSelectTable($event)
                    },
                  },
                  model: {
                    value: _vm.tableName,
                    callback: function ($$v) {
                      _vm.tableName = $$v
                    },
                    expression: "tableName",
                  },
                },
                _vm._l(_vm.tables, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.tableName
        ? [
            _c("div", { staticClass: "title-label" }, [
              _vm._v("\n      客户:" + _vm._s(_vm.clientName)),
              _vm.tableName
                ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("表名:" + _vm._s(_vm.tableName)),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("保存")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addClientProp } },
              [_vm._v("新增")]
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.fullscreenLoading,
                    expression: "fullscreenLoading",
                  },
                ],
                staticStyle: { width: "100%", "margin-top": "10px" },
                attrs: {
                  data: _vm.filterClientProp,
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(0, 0, 0, 0.8)",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "c_id", label: "ID", width: "80" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "c_code", label: "Code", width: "250" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: !!scope.row.c_id },
                              model: {
                                value: scope.row.c_code,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "c_code", $$v)
                                },
                                expression: "scope.row.c_code",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1998693427
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "c_label_code",
                    label: "Label Code",
                    width: "350",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: !!scope.row.c_id },
                              model: {
                                value: scope.row.c_label_code,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "c_label_code", $$v)
                                },
                                expression: "scope.row.c_label_code",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3397159594
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "c_type", label: "类型", width: "150" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择",
                                  clearable: "",
                                },
                                model: {
                                  value: scope.row.c_type,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "c_type", $$v)
                                  },
                                  expression: "scope.row.c_type",
                                },
                              },
                              _vm._l(_vm.code.PropType, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3768285254
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "c_is_option",
                    label: "Is Option",
                    width: "150",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: scope.row.c_is_option,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "c_is_option", $$v)
                                  },
                                  expression: "scope.row.c_is_option",
                                },
                              },
                              _vm._l(_vm.code.YesNO, function (item) {
                                return _c("el-option", {
                                  key: item.key,
                                  attrs: { label: item.value, value: item.key },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    6071030
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "c_display_order",
                    label: "排序",
                    width: "150",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              attrs: { min: _vm.minValue, size: "small" },
                              model: {
                                value: scope.row.c_display_order,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "c_display_order", $$v)
                                },
                                expression: "scope.row.c_display_order",
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2349754589
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "300" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteClientProp(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.editClientProp(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _vm._v(" "),
                            _vm.editOption(scope.row)
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.editClientPropOption(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑Option")]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    892017628
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.dialogClientPropVisible,
                  title: _vm.dialogClientPropTitle,
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogClientPropVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.confirmClientPropItem(_vm.clientPropItem)
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.clientPropItem,
                      "label-width": "150px",
                    },
                  },
                  [
                    _vm.clientPropItem.c_id
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "c_id" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: true },
                              model: {
                                value: _vm.clientPropItem.c_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.clientPropItem, "c_id", $$v)
                                },
                                expression: "clientPropItem.c_id",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_code" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: !!_vm.clientPropItem.c_id },
                          model: {
                            value: _vm.clientPropItem.c_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_code", $$v)
                            },
                            expression: "clientPropItem.c_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_label_code" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: !!_vm.clientPropItem.c_id },
                          model: {
                            value: _vm.clientPropItem.c_label_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_label_code", $$v)
                            },
                            expression: "clientPropItem.c_label_code",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm._l(
                              _vm.getFilterClientLabels(
                                _vm.clientPropItem.client_label
                              ),
                              function (item) {
                                return _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "300px",
                                        display: "inline-block",
                                      },
                                      model: {
                                        value: item.c_label,
                                        callback: function ($$v) {
                                          _vm.$set(item, "c_label", $$v)
                                        },
                                        expression: "item.c_label",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: !!item.c_id,
                                        },
                                        model: {
                                          value: item.c_language_id,
                                          callback: function ($$v) {
                                            _vm.$set(item, "c_language_id", $$v)
                                          },
                                          expression: "item.c_language_id",
                                        },
                                      },
                                      _vm._l(
                                        _vm.code.Language,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.key,
                                            attrs: {
                                              label: item.value,
                                              value: item.key,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { width: "50px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteClientLabel(
                                              _vm.clientPropItem,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm.canAddClientPropLabel(_vm.clientPropItem)
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addClientLabel(
                                            _vm.clientPropItem
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("新增")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_is_custom" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_is_custom,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_is_custom", $$v)
                              },
                              expression: "clientPropItem.c_is_custom",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_parent_id" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_parent_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_parent_id", $$v)
                            },
                            expression: "clientPropItem.c_parent_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_prop_group_id" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_prop_group_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.clientPropItem,
                                "c_prop_group_id",
                                $$v
                              )
                            },
                            expression: "clientPropItem.c_prop_group_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.clientPropItem.c_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_type", $$v)
                              },
                              expression: "clientPropItem.c_type",
                            },
                          },
                          _vm._l(_vm.code.PropType, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_is_option" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_is_option,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_is_option", $$v)
                              },
                              expression: "clientPropItem.c_is_option",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_width" } },
                      [
                        _c("el-input-number", {
                          attrs: { min: _vm.minValue, size: "small" },
                          model: {
                            value: _vm.clientPropItem.c_width,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_width", $$v)
                            },
                            expression: "clientPropItem.c_width",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_fixed" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_fixed,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_fixed", $$v)
                              },
                              expression: "clientPropItem.c_fixed",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_visible" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_visible,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_visible", $$v)
                              },
                              expression: "clientPropItem.c_visible",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_sortable" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_sortable,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_sortable", $$v)
                              },
                              expression: "clientPropItem.c_sortable",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_queryable" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_queryable,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_queryable", $$v)
                              },
                              expression: "clientPropItem.c_queryable",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_editable" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_editable,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_editable", $$v)
                              },
                              expression: "clientPropItem.c_editable",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_batcheditable" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_batcheditable,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.clientPropItem,
                                  "c_batcheditable",
                                  $$v
                                )
                              },
                              expression: "clientPropItem.c_batcheditable",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_tree_url" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_tree_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_tree_url", $$v)
                            },
                            expression: "clientPropItem.c_tree_url",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_option_key" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_option_key,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_option_key", $$v)
                            },
                            expression: "clientPropItem.c_option_key",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_option_label" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_option_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.clientPropItem,
                                "c_option_label",
                                $$v
                              )
                            },
                            expression: "clientPropItem.c_option_label",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_options_url" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.clientPropItem.c_options_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.clientPropItem, "c_options_url", $$v)
                            },
                            expression: "clientPropItem.c_options_url",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_display_order" } },
                      [
                        _c("el-input-number", {
                          attrs: { min: _vm.minValue, size: "small" },
                          model: {
                            value: _vm.clientPropItem.c_display_order,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.clientPropItem,
                                "c_display_order",
                                $$v
                              )
                            },
                            expression: "clientPropItem.c_display_order",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_linkedit" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "small" },
                            model: {
                              value: _vm.clientPropItem.c_linkedit,
                              callback: function ($$v) {
                                _vm.$set(_vm.clientPropItem, "c_linkedit", $$v)
                              },
                              expression: "clientPropItem.c_linkedit",
                            },
                          },
                          _vm._l(_vm.code.YesNO, function (item, key) {
                            return _c(
                              "el-radio",
                              { key: key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_option_table" } },
                      [
                        _c("el-input", {
                          attrs: { min: _vm.minValue },
                          model: {
                            value: _vm.clientPropItem.c_option_table,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.clientPropItem,
                                "c_option_table",
                                $$v
                              )
                            },
                            expression: "clientPropItem.c_option_table",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_common_prop_id" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: true,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onCommonPropSelect(
                                  _vm.clientPropItem,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.clientPropItem.c_common_prop_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.clientPropItem,
                                  "c_common_prop_id",
                                  $$v
                                )
                              },
                              expression: "clientPropItem.c_common_prop_id",
                            },
                          },
                          _vm._l(_vm.commonProps, function (item) {
                            return _c("el-option", {
                              key: item.c_id,
                              attrs: {
                                label: item.c_label_code,
                                value: item.c_id,
                              },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isNull(
                          _vm.clientPropItem.__common_prop_option_client_label
                        )
                          ? _c(
                              "el-table",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  data: _vm.clientPropItem
                                    .__common_prop_option_client_label,
                                  "element-loading-spinner": "el-icon-loading",
                                  "element-loading-background":
                                    "rgba(0, 0, 0, 0.8)",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "c_label_code",
                                    label: "Label Code",
                                    width: "350",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "c_label",
                                    label: "Label",
                                    width: "150",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "c_label",
                                    label: "Label",
                                    width: "150",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择",
                                                  disabled: true,
                                                },
                                                model: {
                                                  value:
                                                    scope.row.c_language_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "c_language_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.c_language_id",
                                                },
                                              },
                                              _vm._l(
                                                _vm.code.Language,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.key,
                                                    attrs: {
                                                      label: item.value,
                                                      value: item.key,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    45665628
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "c_is_virtual" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.clientPropItem.c_is_virtual,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.clientPropItem,
                                  "c_is_virtual",
                                  $$v
                                )
                              },
                              expression: "clientPropItem.c_is_virtual",
                            },
                          },
                          _vm._l(_vm.c_is_virtual_list, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "80%",
                  visible: _vm.dialogPropOptionVisible,
                  title: _vm.dialogClientPropTitle,
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogPropOptionVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.confirmPropOptionDialog },
                  },
                  [_vm._v("保存")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addPropOption },
                  },
                  [_vm._v("单项新增")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.batchAddPropOption },
                  },
                  [_vm._v("批量新增")]
                ),
                _vm._v(" "),
                _vm.batchAddPropOptionVisible
                  ? _c(
                      "div",
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "400px",
                            margin: "10px 0",
                            display: "block",
                          },
                          attrs: {
                            type: "textarea",
                            rows: 5,
                            placeholder: "请输入标签列表",
                          },
                          model: {
                            value: _vm.batchAddPropOptionContent,
                            callback: function ($$v) {
                              _vm.batchAddPropOptionContent = $$v
                            },
                            expression: "batchAddPropOptionContent",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.confirmBatchAddPropOption },
                          },
                          [_vm._v("确定批量新增")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          { on: { click: _vm.cancelBatchAddPropOption } },
                          [_vm._v("取消批量新增")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.fullscreenLoading,
                        expression: "fullscreenLoading",
                      },
                    ],
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      data: _vm.filterClientPropOptions,
                      "element-loading-spinner": "el-icon-loading",
                      "element-loading-background": "rgba(0, 0, 0, 0.8)",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "c_id", label: "ID", width: "50" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "c_label_code",
                        label: "Label Code",
                        width: "350",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { disabled: !!scope.row.c_id },
                                  model: {
                                    value: scope.row.c_label_code,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "c_label_code", $$v)
                                    },
                                    expression: "scope.row.c_label_code",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3397159594
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "client_label",
                        label: "Client Label",
                        width: "450",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _vm._l(
                                      _vm.getFilterClientLabels(
                                        scope.row.client_label
                                      ),
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "225px",
                                                display: "inline-block",
                                              },
                                              model: {
                                                value: item.c_label,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "c_label", $$v)
                                                },
                                                expression: "item.c_label",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                  disabled: !!item.c_id,
                                                },
                                                model: {
                                                  value: item.c_language_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "c_language_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.c_language_id",
                                                },
                                              },
                                              _vm._l(
                                                _vm.code.Language,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.key,
                                                    attrs: {
                                                      label: item.value,
                                                      value: item.key,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: { width: "50px" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteClientLabel(
                                                      scope.row,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.showOptionLabelAdd(scope.row)
                                      ? [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addClientLabel(
                                                    scope.row,
                                                    null,
                                                    "option"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("新增")]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2569736721
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "c_parent_id",
                        label: "Parent Id",
                        width: "100",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.c_parent_id,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "c_parent_id", $$v)
                                    },
                                    expression: "scope.row.c_parent_id",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1328525883
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "c_display_order",
                        label: "Display Order",
                        width: "150",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  attrs: { min: _vm.minValue, size: "small" },
                                  model: {
                                    value: scope.row.c_display_order,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "c_display_order",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.c_display_order",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2349754589
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "c_common_option_value",
                        label: "Common Option Value",
                        width: "150",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.c_common_option_value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "c_common_option_value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scope.row.c_common_option_value",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        946962288
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "150" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteClientPropOption(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3349210230
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }